import React from "react";

import "./MatchCard.scss";


const MatchCard = (props) => {
    const { data, onClick = () => {} } = props;

    return (
        <div className="match-card__wrapper d-flex align-items-center mx-3 mb-3" onClick={onClick}>
            <img src={data?.ProfilePictureUrl} />
            <div className="">
                <p className="pl-2">{data?.Name}</p>
                <p className="percentage pl-2 pt-2">{data?.MatchPercentage > 100 ? "100": data?.MatchPercentage}% match</p>
            </div>
        </div>
    );
};
export default MatchCard;
