var Config = {
    LIMIT: "10",
    PERSIST_SECRET_KEY: "1X4NVHoHCok]3WG;aOPF",
    ENVIRONMENT: "PRODUCTION",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://api.halalconnections.com",
            FIREBASE_API_KEY: "AIzaSyDg9IfmZ-92wHSoJ-Y2aYE5McMu7M72pTs",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b"
        },
        DEVELOPMENT: {
            API_URL: "https://api.halalconnections.com",
            FIREBASE_API_KEY: "AIzaSyDg9IfmZ-92wHSoJ-Y2aYE5McMu7M72pTs",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b"
        },
        QA: {
            API_URL: "https://api.halalconnections.com",
            FIREBASE_API_KEY: "AIzaSyDg9IfmZ-92wHSoJ-Y2aYE5McMu7M72pTs",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b"
        },
        STAGING: {
            API_URL: "https://api.halalconnections.com",
            FIREBASE_API_KEY: "AIzaSyDg9IfmZ-92wHSoJ-Y2aYE5McMu7M72pTs",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b"
        },
        PRODUCTION: {
            API_URL: "https://api.halalconnections.com",
            FIREBASE_API_KEY: "AIzaSyDg9IfmZ-92wHSoJ-Y2aYE5McMu7M72pTs",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;